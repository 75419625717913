<template>
    <section class="media">
        <v-container>
            <v-row align="start" justify="center">
                <v-col
                    class="d-flex child-flex"
                    cols="3"
                    v-for="(item, i) in 24"
                    :key="i"
                >
                    <v-img
                        :src="require(`../assets/sunnycenter-0${i + 1}.jpeg`)"
                        :lazy-src="
                            require(`../assets/sunnycenter-0${i + 1}.jpeg`)
                        "
                        aspect-ratio="1"
                        class="pink lighten-2"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    data: () => ({
        images: [
            {
                image: '../assets/sunnycenter-01.jpeg',
            },
            {
                image: '../assets/sunnycenter-02.jpeg',
            },
            {
                image: '../assets/sunnycenter-03.jpeg',
            },
            {
                image: '../assets/sunnycenter-04.jpeg',
            },
            {
                image: '../assets/sunnycenter-05.jpeg',
            },
            {
                image: '../assets/sunnycenter-06.jpeg',
            },
            {
                image: './assets/sunnycenter-07.jpeg',
            },
            {
                image: './assets/sunnycenter-08.jpeg',
            },
            {
                image: './assets/sunnycenter-09.jpeg',
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.media {
    margin-bottom: 200px;
}
</style>
